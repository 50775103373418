import React, { Fragment } from 'react';
import { type BaseChainflipAsset } from '@chainflip/utils/chainflip';
import { differenceInUtcDays, toEndOfUtcDay, toStartOfUtcDay } from '@chainflip/utils/date';
import { utc, type UTCDate } from '@date-fns/utc';
import { Popover, Transition } from '@headlessui/react';
import {
  useCalendars,
  useDatePickerState,
  useDaysPropGetters,
  useMonthsActions,
  useMonthsPropGetters,
} from '@rehookify/datepicker';
import classNames from 'classnames';
import { isWithinInterval, subDays } from 'date-fns';
import { Button, Toggle } from '@/shared/components';
import { ChevronIcon } from '@/shared/icons/large';
import { ArrowIcon, CalendarIcon } from '@/shared/icons/small';
import {
  type DatesRange,
  getDatesLabel,
  getSelectedTimeframe,
  localDayToUTCDay,
  minSwapDate,
  timeframes,
} from '@/shared/utils';

const maxDate = toStartOfUtcDay(new Date());

export const isComparableDates = (datesToCompare: DatesRange) =>
  datesToCompare.every((date) =>
    isWithinInterval(date, { start: minSwapDate, end: toEndOfUtcDay(maxDate) }, { in: utc }),
  );

export const getDatesToCompare = (startdate: UTCDate, endDate: UTCDate): DatesRange => [
  toStartOfUtcDay(subDays(startdate, differenceInUtcDays({ start: startdate, end: endDate }) + 1)),
  toEndOfUtcDay(subDays(startdate, 1)),
];

export const PeriodSelector = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedDates,
  setSelectedDates,
  setSelectedPools,
  compareDatesEnabled,
  toggleCompareDatesEnabled,
  panelClassName,
  buttonSize = 'small',
}: {
  startDate: UTCDate;
  endDate: UTCDate;
  setStartDate: (date: UTCDate) => void;
  setEndDate: (date: UTCDate) => void;
  selectedDates: UTCDate[];
  setSelectedDates: React.Dispatch<React.SetStateAction<UTCDate[]>>;
  setSelectedPools?: (assets: BaseChainflipAsset[]) => void;
  compareDatesEnabled?: boolean;
  toggleCompareDatesEnabled?: () => void;
  panelClassName?: string;
  buttonSize?: 'small' | 'medium' | 'large';
}) => (
  <Popover>
    {({ open }) => {
      const dpState = useDatePickerState({
        selectedDates,
        onDatesChange: (dates) => {
          const utcDates = [localDayToUTCDay(dates[0])];
          if (dates.length === 2) {
            utcDates.push(toEndOfUtcDay(localDayToUTCDay(dates[1])));
            setStartDate(utcDates[0]);
            setEndDate(utcDates[1]);
            if (compareDatesEnabled && isComparableDates(utcDates as DatesRange))
              setSelectedPools?.([]);
          }

          setSelectedDates(utcDates);
        },
        dates: {
          mode: 'range',
          minDate: minSwapDate,
          maxDate,
          selectSameDate: true,
          toggle: false,
        },
        calendar: {
          mode: 'fluid',
        },
      });
      const {
        calendars: [{ year, month, days }],
        weekDays,
      } = useCalendars(dpState);
      const { nextMonthButton, previousMonthButton } = useMonthsPropGetters(dpState);
      const { dayButton } = useDaysPropGetters(dpState);
      const { setMonth } = useMonthsActions(dpState);

      // selectedDates[1] is not set when range selection is not finished
      const buttonEndDate =
        selectedDates[1] ??
        (dpState.state.rangeEnd && localDayToUTCDay(dpState.state.rangeEnd)) ??
        selectedDates[0];

      const buttonDates = [selectedDates[0], buttonEndDate].sort(
        (a, b) => a.getTime() - b.getTime(),
      );

      const selectedTimeframe = getSelectedTimeframe(
        selectedDates[0],
        toEndOfUtcDay(buttonEndDate),
      );

      const datesToCompare = getDatesToCompare(buttonDates[0], buttonDates[1]);

      // check if datesToCompare is within calendars min-max range and can be comparable
      const isComparable = Boolean(compareDatesEnabled) && isComparableDates(datesToCompare);

      const isCompareStartDate = (dateFromCalendar: Date) =>
        localDayToUTCDay(dateFromCalendar).getTime() === datesToCompare[0].getTime();
      const isCompareEndDate = (dateFromCalendar: Date) =>
        toEndOfUtcDay(localDayToUTCDay(dateFromCalendar)).getTime() === datesToCompare[1].getTime();

      const buttonLabel = getDatesLabel(
        [selectedDates[0], buttonEndDate].sort((a, b) => a.getTime() - b.getTime()) as DatesRange,
      );
      const compareDatesLabel = getDatesLabel([datesToCompare[0], datesToCompare[1]]);

      return (
        <div className="relative w-full text-14">
          <Popover.Button as="div">
            <Button
              size={buttonSize}
              type="secondary-standard"
              className={classNames(
                '!text-12 hover:enabled:![background:linear-gradient(180deg,rgba(255,255,255,0.02)_0%,rgba(255,255,255,0.00)_100%),#2B2B2B]',
                open &&
                  'border-[#292929] text-cf-white shadow-[0px_0px_0px_1.5px_rgba(58,58,58,0.36),0px_0px_0px_1px_rgba(255,255,255,0.24)] [background:linear-gradient(180deg,rgba(255,255,255,0.02)_0%,rgba(255,255,255,0.00)_100%),#2B2B2B]',
              )}
            >
              <div className="flex items-center">
                <CalendarIcon width={16} className="mr-0.5" />
                <div className="flex gap-x-1">
                  {selectedTimeframe?.name ?? (
                    <div className="flex items-center gap-x-1">
                      <span
                        suppressHydrationWarning
                        className={classNames(
                          buttonEndDate.getTime() < selectedDates[0].getTime() && 'text-cf-light-1',
                        )}
                      >
                        {buttonLabel.startDate}
                      </span>
                      <span>-</span>
                      <span
                        suppressHydrationWarning
                        className={classNames(
                          buttonEndDate.getTime() > selectedDates[0].getTime() &&
                            buttonEndDate !== selectedDates[1] &&
                            'text-cf-light-1',
                        )}
                      >
                        {buttonLabel.endDate}
                      </span>
                    </div>
                  )}
                  {isComparable && (
                    <>
                      <span>vs</span>
                      <span>Previous period</span>
                    </>
                  )}
                </div>
              </div>
              <ChevronIcon
                flip={!open}
                className="text-cf-white transition group-hover:text-cf-white"
              />
            </Button>
          </Popover.Button>
          <Transition
            as={Fragment}
            afterLeave={() => setSelectedDates([startDate, endDate])}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              className={classNames(
                panelClassName,
                'absolute right-0 z-10 mt-2 flex w-max space-y-2 rounded-md border border-cf-gray-5 bg-cf-gray-4 p-4 text-12',
              )}
              style={{
                boxShadow: 'shadow-grayPop2',
              }}
            >
              <div className="flex flex-col gap-2">
                {timeframes.map((timeframe) => (
                  <button
                    key={timeframe.name}
                    type="button"
                    disabled={timeframe === selectedTimeframe}
                    className={classNames(
                      timeframe === selectedTimeframe &&
                        '!border-cf-light-1 bg-cf-gray-5 text-white',
                      timeframe !== selectedTimeframe &&
                        'hover:border-cf-gray-5 hover:bg-[#2B2B2B]',
                      'transition:ease-out w-[105px] rounded-md border border-transparent px-2 py-1.5 text-start duration-100',
                    )}
                    onClick={() => {
                      setStartDate(timeframe.startDate);
                      setEndDate(timeframe.endDate);
                      setSelectedDates([timeframe.startDate, timeframe.endDate]);
                      setMonth(timeframe.endDate);
                      if (
                        compareDatesEnabled &&
                        isComparableDates([timeframe.startDate, timeframe.endDate])
                      )
                        setSelectedPools?.([]);
                    }}
                  >
                    {timeframe.name}
                  </button>
                ))}
              </div>
              <div className="ml-3 hidden border-s border-[#2B2B2B] pl-3 md:block">
                <div className="flex items-center justify-between space-x-2 text-cf-white">
                  <button
                    type="button"
                    className="disabled:text-cf-light-1"
                    {...previousMonthButton()}
                  >
                    <ArrowIcon direction="left" />
                  </button>
                  <p>
                    {month} {year}
                  </p>
                  <button type="button" className="disabled:text-cf-light-1" {...nextMonthButton()}>
                    <ArrowIcon direction="right" />
                  </button>
                </div>
                <div className="grid grid-cols-7 pt-2 lg:pb-4">
                  {weekDays.map((day) => (
                    <div key={`${month}-${day}`} className="px-1 py-2 text-center text-cf-light-2">
                      {day}
                    </div>
                  ))}
                  {days.map((dpDay) => (
                    <button
                      type="button"
                      key={dpDay.$date.toDateString()}
                      // Disable button if selected and no second date is selected
                      disabled={dpDay.selected && !selectedDates[1]}
                      className={classNames(
                        `my-px border-none px-1 py-1.5 disabled:text-cf-light-1`,
                        dpDay.selected && 'bg-cf-green-1 !text-cf-white',
                        (dpDay.range.includes('range-start') ||
                          (!dpDay.selected && isComparable && isCompareStartDate(dpDay.$date))) &&
                          'rounded-l',
                        (dpDay.range.includes('range-end') ||
                          (!dpDay.selected && isComparable && isCompareEndDate(dpDay.$date))) &&
                          'rounded-r',
                        !dpDay.selected && dpDay.range !== '' && 'bg-cf-gray-5',
                        isComparable &&
                          isWithinInterval(
                            localDayToUTCDay(dpDay.$date),
                            {
                              start: datesToCompare[0],
                              end: datesToCompare[1],
                            },
                            { in: utc },
                          ) &&
                          'bg-cf-gray-3-5',
                      )}
                      {...dayButton(dpDay)}
                    >
                      {dpDay.day}
                    </button>
                  ))}
                </div>
                {toggleCompareDatesEnabled && (
                  <div className="hidden lg:block">
                    <div className="flex min-w-[262px] items-center justify-between gap-x-1">
                      <span className="font-bold text-cf-light-2">
                        Compare vs.{' '}
                        <span className="text-cf-light-3">
                          {compareDatesLabel.startDate} - {compareDatesLabel.endDate}
                        </span>
                      </span>
                      <Toggle
                        disabled={
                          !datesToCompare.every((date) =>
                            isWithinInterval(
                              date,
                              { start: minSwapDate, end: maxDate },
                              { in: utc },
                            ),
                          )
                        }
                        label={{ content: '' }}
                        className="bg-cf-gray-5"
                        value={isComparable}
                        onToggle={() => {
                          if (!compareDatesEnabled) {
                            setSelectedPools?.([]);
                          }
                          toggleCompareDatesEnabled();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      );
    }}
  </Popover>
);
